@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9i7c7f');
  src:  url('fonts/icomoon.eot?9i7c7f#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?9i7c7f') format('woff2'),
    url('fonts/icomoon.ttf?9i7c7f') format('truetype'),
    url('fonts/icomoon.woff?9i7c7f') format('woff'),
    url('fonts/icomoon.svg?9i7c7f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-controls-pause:before {
  content: "\e9b0";
  color: #c41f3e;
}
.icon-controls-play:before {
  content: "\e9b1";
  color: #c41f3e;
}
.icon-Apple-face-id:before {
  content: "\e9b2";
  color: #c41f3e;
}
.icon-Android-touch-id:before {
  content: "\e9b3";
  color: #c41f3e;
}
.icon-digital-vault:before {
  content: "\e9b4";
  color: #c41f3e;
}
.icon-shield:before {
  content: "\e9b5";
  color: #c41f3e;
}
.icon-Descending:before {
  content: "\e9b6";
  color: #606366;
}
.icon-Ascending:before {
  content: "\e9b7";
  color: #606366;
}
.icon-Checkmark4:before {
  content: "\e9b8";
  color: #c41f3e;
}
.icon-move-money:before {
  content: "\e900";
  color: #c41f3e;
}
.icon-Forex:before {
  content: "\e901";
  color: #c41f3e;
}
.icon-insights:before {
  content: "\e9ad";
  color: #c41f3e;
}
.icon-search-money:before {
  content: "\e9ac";
  color: #c41f3e;
}
.icon-Report:before {
  content: "\e902";
  color: #c41f3e;
}
.icon-Feedback:before {
  content: "\e903";
  color: #c41f3e;
}
.icon-Bar-graph:before {
  content: "\e904";
  color: #c41f3e;
}
.icon-Present:before {
  content: "\e905";
  color: #c41f3e;
}
.icon-Question:before {
  content: "\e906";
  color: #c41f3e;
}
.icon-Trade:before {
  content: "\e907";
  color: #c41f3e;
}
.icon-Transfer-2:before {
  content: "\e908";
  color: #c41f3e;
}
.icon-Home-2:before {
  content: "\e909";
  color: #c41f3e;
}
.icon-cash-out:before {
  content: "\e90a";
  color: #c41f3e;
}
.icon-cash-in:before {
  content: "\e90b";
  color: #c41f3e;
}
.icon-star-filled:before {
  content: "\e90c";
  color: #c41f3e;
}
.icon-success-filled .path1:before {
  content: "\e90d";
  color: rgb(31, 133, 97);
}
.icon-success-filled .path2:before {
  content: "\e9aa";
  margin-left: -1.0419921875em;
  color: rgb(255, 255, 255);
}
.icon-alert-general-filled .path1:before {
  content: "\e90e";
  color: rgb(196, 31, 62);
}
.icon-alert-general-filled .path2:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-info-diamond-filled .path1:before {
  content: "\e90f";
  color: rgb(153, 78, 4);
}
.icon-info-diamond-filled .path2:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-info-square-filled .path1:before {
  content: "\e910";
  color: rgb(17, 96, 138);
}
.icon-info-square-filled .path2:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Bolt:before {
  content: "\e998";
  color: #c41f3e;
}
.icon-Minimize2:before {
  content: "\e994";
  color: #c41f3e;
}
.icon-Up-Trend:before {
  content: "\e995";
  color: #c41f3e;
}
.icon-Down-Trend:before {
  content: "\e996";
  color: #c41f3e;
}
.icon-Reload2:before {
  content: "\e997";
  color: #c41f3e;
}
.icon-Clock-Fill:before {
  content: "\e911";
  color: #8b1d41;
}
.icon-play:before {
  content: "\e992";
  color: #fff;
}
.icon-download:before {
  content: "\e912";
  color: #c41f3e;
}
.icon-new-eDdoc:before {
  content: "\e913";
  color: #c41f3e;
}
.icon-Knowledge-bank:before {
  content: "\e940";
  color: #c41f3e;
}
.icon-Minus:before {
  content: "\e955";
  color: #c41f3e;
}
.icon-Plus:before {
  content: "\e98f";
  color: #c41f3e;
}
.icon-Caution:before {
  content: "\e914";
  color: #c17200;
}
.icon-Success-Fill-green:before {
  content: "\e915";
  color: #469566;
}
.icon-Status:before {
  content: "\e916";
  color: #c41f3e;
}
.icon-Quotes-and-Research:before {
  content: "\e917";
  color: #c41f3e;
}
.icon-Exclaimation:before {
  content: "\e98e";
  color: #3079ad;
}
.icon-adChoice:before {
  content: "\e918";
  color: #c41f3e;
}
.icon-Account-Summary:before {
  content: "\e919";
  color: #c41f3e;
}
.icon-Trading:before {
  content: "\e977";
  color: #c41f3e;
}
.icon-Site-map:before {
  content: "\e91a";
  color: #c41f3e;
}
.icon-Preferences:before {
  content: "\e984";
  color: #c41f3e;
}
.icon-My-Accounts:before {
  content: "\e91b";
  color: #c41f3e;
}
.icon-Details:before {
  content: "\e91c";
  color: #c41f3e;
}
.icon-Cash-transfer:before {
  content: "\e91d";
  color: #c41f3e;
}
.icon-Lightbulb:before {
  content: "\e950";
  color: #c41f3e;
}
.icon-Add-Content:before {
  content: "\e91e";
  color: #c41f3e;
}
.icon-Add-Favourite:before {
  content: "\e91f";
  color: #c41f3e;
}
.icon-Add-Payee:before {
  content: "\e920";
  color: #c41f3e;
}
.icon-Add:before {
  content: "\e921";
  color: #c41f3e;
}
.icon-Agreement:before {
  content: "\e922";
  color: #c41f3e;
}
.icon-Agriculture:before {
  content: "\e923";
  color: #c41f3e;
}
.icon-American-flat:before {
  content: "\e924";
  color: #c41f3e;
}
.icon-Apply:before {
  content: "\e925";
  color: #c41f3e;
}
.icon-Apps-and-Sites:before {
  content: "\e926";
  color: #c41f3e;
}
.icon-Arrow-Down:before {
  content: "\e927";
  color: #c41f3e;
}
.icon-Arrow-Left:before {
  content: "\e928";
  color: #c41f3e;
}
.icon-Arrow-Right:before {
  content: "\e929";
  color: #c41f3e;
}
.icon-Arrow-Up:before {
  content: "\e92a";
  color: #c41f3e;
}
.icon-Attachment:before {
  content: "\e92b";
  color: #c41f3e;
}
.icon-Bank-Accounts:before {
  content: "\e92c";
  color: #c41f3e;
}
.icon-Bell1:before {
  content: "\e92d";
  color: #c41f3e;
}
.icon-Bell2:before {
  content: "\e92e";
  color: #c41f3e;
}
.icon-Bin:before {
  content: "\e92f";
  color: #c41f3e;
}
.icon-Bookmark:before {
  content: "\e930";
  color: #c41f3e;
}
.icon-Branch:before {
  content: "\e931";
  color: #c41f3e;
}
.icon-Briefcase:before {
  content: "\e932";
  color: #c41f3e;
}
.icon-Building:before {
  content: "\e933";
  color: #c41f3e;
}
.icon-Calculator:before {
  content: "\e934";
  color: #c41f3e;
}
.icon-Calendar:before {
  content: "\e935";
  color: #c41f3e;
}
.icon-Cancel:before {
  content: "\e936";
  color: #c41f3e;
}
.icon-Card:before {
  content: "\e937";
  color: #c41f3e;
}
.icon-Cart:before {
  content: "\e938";
  color: #c41f3e;
}
.icon-Chat:before {
  content: "\e939";
  color: #c41f3e;
}
.icon-Check:before {
  content: "\e93a";
  color: #606366;
}
.icon-Checkmark-Fill:before {
  content: "\e93b";
  color: #c41f3e;
}
.icon-Checkmark:before {
  content: "\e93c";
  color: #c41f3e;
}
.icon-Checkmark-Thin:before {
  content: "\e93d";
  color: #c41f3e;
}
.icon-Checkmark-Thick:before {
  content: "\e93e";
  color: #c41f3e;
}
.icon-Chevron-Down:before {
  content: "\e93f";
  color: #c41f3e;
}
.icon-Chevron-Left:before {
  content: "\e941";
  color: #c41f3e;
}
.icon-Chevron-Right:before {
  content: "\e942";
  color: #c41f3e;
}
.icon-Chevron-Up:before {
  content: "\e943";
  color: #c41f3e;
}
.icon-Close-Circle:before {
  content: "\e944";
  color: #c41f3e;
}
.icon-Close:before {
  content: "\e945";
  color: #c41f3e;
}
.icon-Customer-Service:before {
  content: "\e946";
  color: #c41f3e;
}
.icon-Do-Not:before {
  content: "\e947";
  color: #c41f3e;
}
.icon-Document:before {
  content: "\e948";
  color: #c41f3e;
}
.icon-Dollar:before {
  content: "\e949";
  color: #c41f3e;
}
.icon-Dollar1:before {
  content: "\e94a";
  color: #c41f3e;
}
.icon-Down-arrow:before {
  content: "\e94b";
  color: #c41f3e;
}
.icon-E-Transfer:before {
  content: "\e94c";
  color: #c41f3e;
}
.icon-eDeposit:before {
  content: "\e94d";
  color: #c41f3e;
}
.icon-Edit:before {
  content: "\e94e";
  color: #c41f3e;
}
.icon-Education:before {
  content: "\e94f";
  color: #c41f3e;
}
.icon-Email-Compose:before {
  content: "\e951";
  color: #c41f3e;
}
.icon-Email-Sent:before {
  content: "\e952";
  color: #c41f3e;
}
.icon-Error-Warning-Fill:before {
  content: "\e953";
  color: #c41f3e;
}
.icon-Error-Warning:before {
  content: "\e954";
  color: #c41f3e;
}
.icon-Exchange:before {
  content: "\e956";
  color: #c41f3e;
}
.icon-Expert-Advice:before {
  content: "\e957";
  color: #c41f3e;
}
.icon-Filter:before {
  content: "\e958";
  color: #c41f3e;
}
.icon-Flag:before {
  content: "\e959";
  color: #c41f3e;
}
.icon-Foreign-Exchange:before {
  content: "\e95a";
  color: #c41f3e;
}
.icon-Global-Money-Transfer-1:before {
  content: "\e95b";
  color: #c41f3e;
}
.icon-Global-Money-Transfer-2:before {
  content: "\e95c";
  color: #c41f3e;
}
.icon-Gold-and-Silver:before {
  content: "\e95d";
  color: #c41f3e;
}
.icon-Graph:before {
  content: "\e95e";
  color: #c41f3e;
}
.icon-Hamburger:before {
  content: "\e95f";
  color: #c41f3e;
}
.icon-Healthcheck:before {
  content: "\e960";
  color: #c41f3e;
}
.icon-Heart:before {
  content: "\e961";
  color: #c41f3e;
}
.icon-Help:before {
  content: "\e962";
  color: #c41f3e;
}
.icon-Home:before {
  content: "\e963";
  color: #c41f3e;
}
.icon-Info-Circle-Fill:before {
  content: "\e964";
  color: #c41f3e;
}
.icon-Info-Circle:before {
  content: "\e965";
  color: #c41f3e;
}
.icon-Info-Triangle-Fill:before {
  content: "\e966";
  color: #c41f3e;
}
.icon-Info-Triangle:before {
  content: "\e967";
  color: #c41f3e;
}
.icon-insights1:before {
  content: "\e968";
  color: #c41f3e;
}
.icon-Left-arrow:before {
  content: "\e969";
  color: #c41f3e;
}
.icon-List:before {
  content: "\e96a";
  color: #c41f3e;
}
.icon-Location:before {
  content: "\e96b";
  color: #c41f3e;
}
.icon-Lock:before {
  content: "\e96c";
  color: #c41f3e;
}
.icon-Mail:before {
  content: "\e96d";
  color: #c41f3e;
}
.icon-Maximize:before {
  content: "\e96e";
  color: #c41f3e;
}
.icon-Megaphone:before {
  content: "\e96f";
  color: #c41f3e;
}
.icon-Minimize:before {
  content: "\e970";
  color: #c41f3e;
}
.icon-Mobile:before {
  content: "\e971";
  color: #c41f3e;
}
.icon-More:before {
  content: "\e972";
  color: #606366;
}
.icon-Mortgage-Payment:before {
  content: "\e973";
  color: #c41f3e;
}
.icon-News:before {
  content: "\e974";
  color: #c41f3e;
}
.icon-Offers:before {
  content: "\e975";
  color: #c41f3e;
}
.icon-Out-of-App:before {
  content: "\e976";
  color: #c41f3e;
}
.icon-Pay-with-Points:before {
  content: "\e978";
  color: #c41f3e;
}
.icon-PDF:before {
  content: "\e979";
  color: #c41f3e;
}
.icon-Pencil:before {
  content: "\e97a";
  color: #c41f3e;
}
.icon-Pending-Fill:before {
  content: "\e97b";
  color: #c41f3e;
}
.icon-Pending:before {
  content: "\e97c";
  color: #c41f3e;
}
.icon-Phone:before {
  content: "\e97d";
  color: #c41f3e;
}
.icon-Pie-Graph:before {
  content: "\e97e";
  color: #c41f3e;
}
.icon-Plane:before {
  content: "\e97f";
  color: #c41f3e;
}
.icon-Plane2:before {
  content: "\e980";
  color: #c41f3e;
}
.icon-Print:before {
  content: "\e981";
  color: #c41f3e;
}
.icon-Profile:before {
  content: "\e982";
  color: #c41f3e;
}
.icon-Question-Thin:before {
  content: "\e983";
  color: #c41f3e;
}
.icon-Question-Thick:before {
  content: "\e985";
  color: #c41f3e;
}
.icon-Quick-Look:before {
  content: "\e986";
  color: #c41f3e;
}
.icon-Rate:before {
  content: "\e987";
  color: #c41f3e;
}
.icon-Reload:before {
  content: "\e988";
  color: #c41f3e;
}
.icon-Reminder:before {
  content: "\e989";
  color: #c41f3e;
}
.icon-Research:before {
  content: "\e98a";
  color: #c41f3e;
}
.icon-Ribbon:before {
  content: "\e98b";
  color: #c41f3e;
}
.icon-Right-arrow:before {
  content: "\e98c";
  color: #c41f3e;
}
.icon-Sailboat:before {
  content: "\e98d";
  color: #c41f3e;
}
.icon-Save:before {
  content: "\e990";
  color: #c41f3e;
}
.icon-Search:before {
  content: "\e991";
  color: #c41f3e;
}
.icon-Sent-Fill:before {
  content: "\e993";
  color: #c41f3e;
}
.icon-Share:before {
  content: "\e999";
  color: #c41f3e;
}
.icon-Shield:before {
  content: "\e99a";
  color: #c41f3e;
}
.icon-Star:before {
  content: "\e99b";
  color: #c41f3e;
}
.icon-Sticky:before {
  content: "\e99c";
  color: #c41f3e;
}
.icon-Success-Fill:before {
  content: "\e99d";
  color: #c41f3e;
}
.icon-Success:before {
  content: "\e99e";
  color: #c41f3e;
}
.icon-Switch:before {
  content: "\e99f";
  color: #c41f3e;
}
.icon-Tag:before {
  content: "\e9a0";
  color: #c41f3e;
}
.icon-Take-Tour:before {
  content: "\e9a1";
  color: #c41f3e;
}
.icon-Thumbs-down:before {
  content: "\e9a2";
  color: #c41f3e;
}
.icon-Thumbs-up:before {
  content: "\e9a3";
  color: #c41f3e;
}
.icon-Transfer:before {
  content: "\e9a4";
  color: #c41f3e;
}
.icon-Trash:before {
  content: "\e9a5";
  color: #c41f3e;
}
.icon-Travel-Tools:before {
  content: "\e9a6";
  color: #c41f3e;
}
.icon-Unlock:before {
  content: "\e9a7";
  color: #c41f3e;
}
.icon-Up-arrow:before {
  content: "\e9a8";
  color: #c41f3e;
}
.icon-Wallet:before {
  content: "\e9a9";
  color: #c41f3e;
}
